<ng-container *ngIf="sidebar">
  <div
    class="sidebar-toggler"
    [class.sidebar-toggler--opened]="sideMenuOpened"
    (click)="onShowSideMenu()"
  ></div>

  <div class="common-sidebar" [class.common-sidebar--show]="showSidebar">
    <!-- <div class="common-sidebar__search" *ngIf="showCompanySearch && companies.length && (isArchonEmployee || (!isArchonEmployee && canSeeBranches))">
      <ngx-select-dropdown
        class="common-sidebar__search-field"
        [(ngModel)]="selectedCompany"
        [multiple]="false"
        [config]="companySelectConfig"
        [options]="companies"
        (change)="selectCompany($event)"
      ></ngx-select-dropdown>
    </div> -->
    <!-- <div class="common-sidebar__search" *ngIf="showEmployeeSearch && employees.length">
      <ngx-select-dropdown
        class="common-sidebar__search-field"
        [(ngModel)]="selectedEmployee"
        [multiple]="false"
        [config]="employeeSelectConfig"
        [options]="employees"
        (change)="selectEmployee($event)"
      ></ngx-select-dropdown>
    </div> -->

    <!-- <div class="common-sidebar__category">
      <img class="common-sidebar__category-icon" [src]="'assets/images/sidebar/' + sidebar.icon" />
      <p>{{ sidebar.category | translate }}</p>
    </div>

    <div class="common-sidebar__group-wrapper" *ngFor="let group of sidebar.group">
      <ng-container *ngIf="group.show">
        <div class="common-sidebar__group">
          <img class="common-sidebar__group-icon" [src]="'assets/images/sidebar/' + group.icon" />
          <p class="common-sidebar__group-name">{{ group.name | translate }}</p>
        </div>

        <div class="common-sidebar__item-list">
          <ng-container *ngTemplateOutlet="sidebarItems; context: { items: group.items }">
          </ng-container>
        </div>
      </ng-container>
    </div> -->

    <img
      class="common-sidebar__logo"
      src="assets/images/sidebar/sidebar-image.svg"
      alt="Sidebar Image"
    />
  </div>
</ng-container>

<ng-template #sidebarItems let-items="items">
  <!-- <ng-container *ngFor="let item of items">
    <ng-container *ngIf="item.show">
      <ng-container
        *ngIf="
          !(
            (item.name === 'navigation.sidebar.item.duct-leakage-verification' &&
              t24Data &&
              !t24Data.tests.testDLT) ||
            (item.name === 'navigation.sidebar.item.airflow-verification' &&
              t24Data &&
              !t24Data.tests.testAirflow) ||
            (item.name === 'navigation.sidebar.item.fan-watt-draw' &&
              t24Data &&
              !t24Data.tests.testFanWattDraw) ||
            (item.name === 'navigation.sidebar.item.refrigerant-verification' &&
              t24Data &&
              !t24Data.tests.testRefrigerantVerification) ||
            (item.name === 'navigation.sidebar.item.roof-attic' && !generalData) ||
            (item.name === 'navigation.sidebar.item.foundation' && !generalData) ||
            (item.name === 'navigation.sidebar.item.walls' && !generalData) ||
            (item.name === 'navigation.sidebar.item.windows' && !generalData) ||
            (item.name === 'navigation.sidebar.item.heating' && !generalData) ||
            (item.name === 'navigation.sidebar.item.cooling' && !generalData) ||
            (item.name === 'navigation.sidebar.item.duct' && !generalData) ||
            (item.name === 'navigation.sidebar.item.hot-water' && !generalData) ||
            (item.name === 'navigation.sidebar.item.pv-system' && !generalData) ||
            (item.name === 'navigation.sidebar.item.appliances' && !generalData) ||
            (item.name === 'navigation.sidebar.item.outdoor-features' && !generalData) ||
            (item.name === 'navigation.sidebar.item.assessor-electrification' && !generalData)
          )
        "
      >
        <a
          routerLink="{{ routerLink(item.route) }}"
          routerLinkActive="common-sidebar__item--active"
          class="common-sidebar__item"
          [ngClass]="{
            disable:
              (item.name === 'navigation.sidebar.item.caz-testing' &&
                !projectInspection.bpi_requested) ||
              (item.name === 'navigation.sidebar.item.infiltration-results' &&
                !projectInspection.bpi_requested)
          }"
        >
          <span>{{ item.name | translate }}</span>
          <span
            *ngIf="
              (item.name === 'navigation.sidebar.item.company-info' &&
                !userValidations.company_info) ||
              (item.name === 'navigation.sidebar.item.general-contacts' &&
                !userValidations.general_contacts) ||
              (item.name === 'navigation.sidebar.item.company-employees' &&
                !userValidations.company_employees) ||
              (item.name === 'navigation.sidebar.item.accounts' && !userValidations.accounts) ||
              (item.name === 'navigation.sidebar.item.payment-info' && !userValidations.payment_info)
            "
            class="danger-sidebar-option"
            ><img src="assets/images/sidebar/danger-option-flag.png"
          /></span>
        </a>
      </ng-container>
    </ng-container>
  </ng-container> -->
</ng-template>
