import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of } from 'rxjs';
import { TokenStorageService } from '../services/token-storage.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private jwtService: JwtHelperService, private tokenStorageService: TokenStorageService) {}

  public canActivate(): Observable<boolean> {
    if (!this.jwtService.isTokenExpired()) {
      return of(true);
    } else {
      this.tokenStorageService.logout();
    }
  }
}
