import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
// import { SidebarCategoryModel } from '../../../../shared/models/navigation.model';


@Component({
  selector: 'common-sidebar',
  templateUrl: './common-sidebar.component.html',
  styleUrls: ['./common-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommonSidebarComponent {
  @Input() public sidebar = true;
  // @Input() public params: any;
  @Input() public showSidebar: boolean = true;
  // @Input() public showCompanySearch: boolean = false;
  // @Input() public showEmployeeSearch: boolean = false;
  // @Input() public isArchonEmployee: boolean = false;
  // @Input() public canSeeBranches: boolean = false;
  // @Input('companyList')
  // set companyList(value: any[]) {
  //   this.companies = [...value];
  //   this.selectedCompany = (this.companies || []).filter(
  //     ({ id }: any) => id === this.selectedCompanyId
  //   );
  // }
  // @Input('employeeList')
  // set employeeList(value: any[]) {
  //   this.employees = [...value];
  //   this.selectedEmployee = (this.employees || []).filter(
  //     ({ user_account_id }: any) => user_account_id === this.selectedEmployeeId
  //   );
  // }

  @Output() public companySelect: EventEmitter<number> = new EventEmitter();
  @Output() public employeeSelect: EventEmitter<number> = new EventEmitter();

  public selectedCompanyId: number;
  public companies: any[] = [];
  public selectedCompany: any[] = [];
  public companySelectConfig: object = {
    displayKey: 'company_name',
    search: true,
    height: '400px',
    placeholder: 'Company / Branch'
  };
  public selectedEmployeeId: number;
  public employees: any[] = [];
  public selectedEmployee: any[] = [];
  public employeeSelectConfig: object = {
    displayKey: 'full_name',
    search: true,
    height: '400px',
    placeholder: 'Employee'
  };
  public sideMenuOpened: boolean = false;

  public selectCompany({ value }: { value: any }): void {
    const id = (value && value.id) || null;
    // localStorage.setItem(SessionStorageEnum.SelectedCompany, id ? id.toString() : '');
    this.companySelect.emit(id);
  }

  public selectEmployee({ value }: { value: any }): void {
    const id = (value && value.user_account_id) || null;
    // localStorage.setItem(SessionStorageEnum.SelectedEmployee, id ? id.toString() : '');
    this.employeeSelect.emit(id);
  }

  // public routerLink(route: string): string {
  //   if (!this.params.id) {
  //     return route;
  //   }
  //   if (this.params.id && this.params.type) {
  //     return route + this.params.id + '/' + this.params.type;
  //   }
  //   return route + this.params.id;
  // }

  public onShowSideMenu(): void {
    this.sideMenuOpened = !this.sideMenuOpened;
    this.showSidebar = !this.showSidebar;
  }
}
