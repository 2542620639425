<ng-container *ngIf="showAppLoader">
  <div *ngIf="isLoading$ | async" class="overlay">
    <div class="spinner">
      <span class="spinner__text">
        <img src="assets/images/loader.gif" />
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!showAppLoader">
  <div *ngIf="isLoading$ | async"></div>
</ng-container>
